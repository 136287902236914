import {
  	SUMMARY_TASK_LOAD_REQUEST,
  	SUMMARY_TASK_LOAD_SUCCESS,
  	SUMMARY_TASK_LOAD_FAILURE,
} from '../actions/summaryTask'

import {
	KAIZEN_DAY_SAVE_REQUEST,
  	KAIZEN_DAY_SAVE_SUCCESS,
  	KAIZEN_DAY_SAVE_FAILURE,
} from '../actions/kaizenDay'

import {
  	DAY_INFO_SAVE_REQUEST,
  	DAY_INFO_SAVE_SUCCESS,
  	DAY_INFO_SAVE_FAILURE,
} from '../actions/dayInfo'

import update from 'immutability-helper';
import {mergeDeep} from '../functions'

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const summaryTask = (state = [], action) => {
	let newState = "";
	let rows = ""
	switch (action.type) {
		case SUMMARY_TASK_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case SUMMARY_TASK_LOAD_SUCCESS:
			rows = {...action.rows}
      		newState = {...state}
			if( rows ){
			  	Object.keys(rows).map((r) => {
					let row = rows[r]
					newState = mergeDeep({list:{[row.task_id]: {} }},newState)
					newState = update( newState, {
						isFetching: {$set: false},
						list: {
						  	[row.task_order] : {$set: row}
						},
					});

					return true
			  	})
			}
			return newState 
		case SUMMARY_TASK_LOAD_FAILURE:
			return state;


		case KAIZEN_DAY_SAVE_REQUEST:
		case DAY_INFO_SAVE_REQUEST:		
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case KAIZEN_DAY_SAVE_SUCCESS:
		case DAY_INFO_SAVE_SUCCESS:		
			rows = {...action.rows}
      		newState = {...state}
			if( rows.summaryTask ){
				let row = rows.summaryTask
				newState = update( newState, {
					isSaving: {$set: false},
					list: {
					  	[row.task_id] : {$set: row}
					},
				});
			}
			return newState 

		case KAIZEN_DAY_SAVE_FAILURE:
		case DAY_INFO_SAVE_FAILURE:		
			return state;
		default:
			return state
  }
}

export default summaryTask