import React from 'react';

import './KaizenScore.css'
const KaizenScore = (props) => {
    const percent =   props.percent
    let progressClass = ""
    if( percent < 30 ){
    	progressClass = "horrible"
    }else if( percent < 50 ){
    	progressClass = "bad"
    }else if( percent < 70 ){
    	progressClass = "good"
    }else if( percent < 100 ){
    	progressClass = "very-good"
    }else{
    	progressClass = "complete"
    }
    
    let levelStyle = "width"
    let levelClass = "horizontal"
    if(props.vertical){
        levelStyle = "height"
        levelClass = "vertical"
    }

    return (
    	<div className={"kaizen-score "+ progressClass}>
    		<div className={"level stripes " + levelClass} style={{[levelStyle]: percent +"%"}} />
    	</div>
	)
}

export default KaizenScore;