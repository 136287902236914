import { summaryDayService } from '../services';
import { alertActions } from './';


export const summaryDayActions = {
    loadByMonth,
}

export const SUMMARY_DAY_LOAD_REQUEST = 'SUMMARY_DAY_LOAD_REQUEST'
export const SUMMARY_DAY_LOAD_SUCCESS = 'SUMMARY_DAY_LOAD_SUCCESS'
export const SUMMARY_DAY_LOAD_FAILURE = 'SUMMARY_DAY_LOAD_FAILURE'

function loadByMonth(year = '', month = '') {
    return dispatch => {
        dispatch(request( year, month));   
        summaryDayService.loadByMonth(year, month)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(dateFrom, dateTo) { 
        let datePeriod = {dateFrom: dateFrom, dateTo: dateTo}
        return { type: SUMMARY_DAY_LOAD_REQUEST, datePeriod } 
    }
    function success(rows) { return { type: SUMMARY_DAY_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: SUMMARY_DAY_LOAD_FAILURE, error } }
}