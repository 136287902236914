import {fetchApi} from '../helpers';

export const summaryTaskService = {
    loadByMonth,
    loadByDates,
};

function loadByMonth(year = '', month = '') {

    const res  = fetchApi('api/summaryTaskLoadByMonth', {
    	year: year,
        month: month,
    }) 

    return res
}

function loadByDates(dateFrom = '', dateTo = '') {

    const res  = fetchApi('api/summaryTaskLoad', {
    	dateFrom: dateFrom,
        dateTo: dateTo,
    }) 

    return res
}