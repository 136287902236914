import {fetchApi} from '../helpers';

export const taskService = {
	load,
	add,
    saveField,
    saveOrder,
    _delete,
};

function load() {
    const res  = fetchApi('api/taskLoad', {
    }) 

    return res
}

function add(task) {
    const res  = fetchApi('api/taskAdd', {
        task: task,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/taskSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function saveOrder( orderList ) {
    const res  = fetchApi('api/taskSaveOrder', {
        orderList: orderList,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/taskDelete', {
        id: id,
    }) 

    return res
}