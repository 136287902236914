import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import KaizenDay from './kaizen/KaizenDay'
import ColStats from '../../components/ColStats';

import DayScore from '../../components/DayScore';

import { kaizenDayActions,dayInfoActions } from '../../actions'
import {mergeDeep} from '../../functions'

import './KaizenDayContainer.css'

var moment = require("moment");

class KaizenDayContainer extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            dayInfo: {},
        };
        this.handleChangeDayInfo = this.handleChangeDayInfo.bind(this)
        this.handleBlurDayInfo = this.handleBlurDayInfo.bind(this)
    }

    componentWillMount( ){
        const year = this.props.year
        const month = this.props.month
        const day = this.props.day
        const date =    year +"-"+ String(month).padStart(2,"0") +"-"+ String(day).padStart(2,"0")      

        if( !year || !month || !day ) return false
        
        const dispatch = this.props.dispatch
        dispatch( kaizenDayActions.load(date) )
        dispatch( dayInfoActions.load(date) )

    }

    componentWillReceiveProps( nextProps ){
        const dispatch = nextProps.dispatch
        const {kaizenDay,dayInfo} = nextProps

        const year = parseInt(nextProps.year,10)
        const month = parseInt(nextProps.month,10)
        const day = parseInt(nextProps.day,10)
        const date =    year +"-"+ String(month).padStart(2,"0") +"-"+ String(day).padStart(2,"0")      
        
        if( !year || !month || !day ) return false

        const dayList = mergeDeep({[year]:{[month]:{[day]:{}}}}
            ,kaizenDay.list )
        const infoList = mergeDeep({[year]:{[month]:{[day]:{}}}}
            ,dayInfo.list )  

        if( year !== parseInt(this.props.year,10) || month !== parseInt(this.props.month,10) || day !== parseInt(this.props.day,10) ){
            
            if( Object.keys(dayList[year][month][day]).length > 0 ){
                // The month it's already loaded
            }else{
                dispatch( kaizenDayActions.load(date) )
            }

            if( Object.keys(infoList[year][month][day]).length > 0 ){
                // The month it's already loaded
            }else{
                dispatch( dayInfoActions.load(date) )
            }
        }

        if( nextProps.dayInfo ){
            let dayInfo = mergeDeep({list:{ [year]:{[month] : {[day] :{}} } }},
                nextProps.dayInfo)

            if( Object.keys(dayInfo.list[year][month][day]).length > 0
                && !dayInfo.isFetching ){
                const info = dayInfo.list[year][month][day]
                this.setState({
                    dayInfo: info
                })
            }
        }
    }

    handleChangeDayInfo = (event) => {
        
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        let newState = update( this.state, {
            dayInfo: { [name]: {$set: value} },
        });

        this.setState(newState)
    };

    handleBlurDayInfo = (event) => {
        const {dispatch} = this.props

        dispatch(dayInfoActions.save( this.state.dayInfo ))    
    };

    getDay( m, dayList ){
        const tYear =   parseInt(m.format("YYYY"),10)
        const tMonth =  parseInt(m.format("MM"),10)
        const tDay =    parseInt(m.format("DD"),10)

        let day = {}
        if( dayList[tYear] 
            && dayList[tYear][tMonth]
            && dayList[tYear][tMonth][tDay] ){
            day = dayList[tYear][tMonth][tDay]
            return day
        }else{
            return false
        }
    }

    render(){
        if( !this.props.kaizenDay 
            || !this.props.kaizenDay.list 
            || this.props.kaizenDay.isFetching
            || !this.state.dayInfo  ) return false

        const year = this.props.year
        const month = this.props.month
        const day = this.props.day
    	const date =	year +"-"+ month +"-"+ day

        let taskList =   this.getDay( moment(date), this.props.kaizenDay.list )
        
    	return (
            <div className="row kaizen-day-container">
                <div className="day-score-container">
                    <DayScore taskList={taskList} />
                </div>

                <div className="col-8 kaizen-day-col">
                    <KaizenDay 
                        taskList={taskList} 
                        dayInfo={this.state.dayInfo}
                        handleChangeDayInfo={(e) => this.handleChangeDayInfo(e)}
                        handleBlurDayInfo={(e) => this.handleBlurDayInfo(e)}/>
                </div>

                <div className="col-4 stats-col bg-light border-left"> 
                    <ColStats date={date} /> 
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
     kaizenDay: state.kaizenDay,     
     dayInfo: state.dayInfo,     
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KaizenDayContainer);
