import React from 'react'
import Axis from './Axis'

export default ({ scales, margins, svgDimensions,horizontal }) => {
 	const { height, width } = svgDimensions


  	const xProps = {
   	orient: 'Bottom',
   	scale: scales.xScale,
   	translate: `translate(0, ${height - margins.bottom})`,
		// tickSize: height - margins.top - margins.bottom,
  }

  	const yProps = {
   	orient: 'Left',
   	scale: scales.yScale,
   	translate: `translate(${margins.left}, 0)`,
   	tickSize: width - margins.left - margins.right,
  	}

  	let classList = ""
  	if( horizontal ){
  		classList = "horizontal"
  	}

  	return (
   	<g className={classList} >
      	<Axis {...xProps}  />
      	<Axis {...yProps} className={classList} />
   	</g>
  	)
}