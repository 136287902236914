export function isEmpty(obj) {
	 if (obj == null) return true;

	 if (obj.length > 0)    return false;
	 if (obj.length === 0)  return true;
	 if (typeof obj !== "object") return true;
	 for (var key in obj) {
		  if (hasOwnProperty.call(obj, key)) return false;
	 }

	 return true;
}

export function getConstant( constantList, key){
	for (let constant of constantList) {
		if( constant.key === key ){
			return constant;
		}
	 }
	 return {};
}

export function isObject(item) {
	return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
  	const source = sources.shift();

  	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
		  		if (!target[key]) Object.assign(target, { [key]: {} });
		  		
		  		mergeDeep(target[key], source[key]);
			} else {
		  		Object.assign(target, { [key]: source[key] });
			}
	 	}
  	}

  	return mergeDeep(target, ...sources);
}

export function convertObjectId(obj) {
	let res = {}
	if(isObject(obj)){
		Object.keys( obj ).map( (i) => {
			let o = obj[i]
			res[o.id] = o
			
			return true
		})
  	}else if(Array.isArray(obj)){
		obj.map( (o) => {
			res[o.id] = o
			
			return true
		})
  	}else{
		return false
  	}

  	return res
}

export function convertToArray(obj) {
	var arr = [];
	for (var key in obj) {
  		arr.push(obj[key]);
	}

  	return arr
}