export const taskType = { 
	time:{
		value: 'time',
		label: 'Time',
	},
	counter:{
		value: 'counter',
		label: 'Counter',
	},
	allowCompensate:{
		value: 'allow-compensate',
		label: 'Allow Compensate',
	},
	negative:{
		value: 'negative',
		label: 'Negative',
	},
	log:{
		value: 'log',
		label: 'Log',
	},
};