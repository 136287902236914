import {fetchApi} from '../helpers';

export const kaizenDayService = {
    load,
    save,
};

function load(date) {

    const res  = fetchApi('api/kaizenDayLoad', {
        date: date,
    }) 

    return res
}

function save(task) {
    const res  = fetchApi('api/dayTaskSave', {
        task: task,
    }) 

    return res
}