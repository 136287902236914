import { combineReducers } from 'redux'
import auth from './auth'
import alert from './alert'
import dayInfo from './dayInfo'
import kaizenDay from './kaizenDay'
import summaryDay from './summaryDay'
import summaryMonth from './summaryMonth'
import summaryTask from './summaryTask'

import task from './task'

const kaizenApp = combineReducers({
	alert,
	auth,
	dayInfo,
	kaizenDay,
	summaryDay,	
	summaryMonth,
	summaryTask,
	task,
})
 
export default kaizenApp