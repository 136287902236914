import React, {Component} from 'react';
import { connect } from 'react-redux'

import { summaryDayActions } from '../../actions'
import {mergeDeep} from '../../functions'

import MonthMenu from './monthMenu/MonthMenu'

var moment = require("moment");

class MonthMenuContainer extends Component { 
	constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    componentWillReceiveProps(nextProps){
    	const dispatch = this.props.dispatch

    	const year = parseInt(nextProps.year,10)
    	const month = parseInt(nextProps.month,10)
    	if( year !== this.props.year && month !== parseInt(this.props.month,10) ){
    		const summaryMonth = nextProps.summaryMonth
			if( year === summaryMonth.year && month === summaryMonth.month ){
				dispatch( summaryDayActions.loadByMonth(summaryMonth.year, summaryMonth.month ) )
    			this.setState({
    				isOpen: true
    			})
    		}
    	}

    }
	toggleMonth(){
		const dispatch = this.props.dispatch
		const {summaryDay,summaryMonth} = this.props
		
		const summary = mergeDeep({[summaryMonth.year]:{[summaryMonth.month]: {}}}
			,summaryDay.list )
		
		let isOpen = (this.state.isOpen === true )?false:true		
		if( isOpen ){
			if( Object.keys(summary[summaryMonth.year][summaryMonth.month]).length === moment(summaryMonth.year +"-"+ summaryMonth.month).daysInMonth()
	        	){
				// The month it's already loaded
	        }else{
				dispatch( summaryDayActions.loadByMonth(summaryMonth.year, summaryMonth.month ) )
			}
		}
        
        this.setState({isOpen: isOpen})
	}

	render(){
		//if( !this.props.summaryMonth['status']) console.log("WE SHOULD LOAD THEM TO THE STORE")
		
		const summaryMonth = this.props.summaryMonth
		const summaryDay = this.props.summaryDay
		const summaryDayList = mergeDeep({[summaryMonth.year]:{[summaryMonth.month]: {}}}
			,summaryDay.list )
		
		var startDate = moment(summaryMonth.date).clone().startOf('month');
        var endDate = moment(summaryMonth.date).clone().endOf('month');

        let dayList = []
        while ( startDate.isBefore(endDate) && startDate.isBefore(moment()) ) {
        	const year = parseInt(startDate.format("YYYY"),10)
			const month = parseInt(startDate.format("MM"),10)
			const day = parseInt(startDate.format("DD"),10)

        	let sumDay = {
				year: year,
				month: month,
				day: day,
				date: startDate.clone(),
			}
			if( summaryDayList[year][month][day] ){
				sumDay['summary'] = summaryDayList[year][month][day]
			}

            dayList.push(sumDay);
            startDate = startDate.add(1, "day");
        }
        dayList.reverse()
		
		return  <MonthMenu 
			isOpen={this.state.isOpen}
			toggleMonth={() => this.toggleMonth()}
			summaryMonth={this.props.summaryMonth} 
			summaryDayList={dayList}/>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		summaryDay: state.summaryDay, 
	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		dispatch,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthMenuContainer);
