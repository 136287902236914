import React from 'react';
import {  NavLink } from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


const UserInfo = (props) => (
    <div className="card" style={{margin: "16px 0",justifyContent: "center"}}>
        <div className="card-body">
            <strong>Wellcome {props.userName}</strong>
            <NavLink to="/logout">
            	<button className="btn btn-link float-right">
                	<FontAwesomeIcon icon="sign-out-alt" size="lg" style={{padding: "-12px"}} /></button>
            </NavLink>
            <NavLink to="/management">
            	<button className="btn btn-link float-right">
                	<FontAwesomeIcon icon="tasks" size="lg" style={{padding: "-12px"}} /></button>
            </NavLink>
        </div>
    </div>
);

export default UserInfo;