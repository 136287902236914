import React, { Component } from 'react';

import Axes from '../chart/Axes'
import Bars from '../chart/Bars'
import ResponsiveWrapper from '../chart/ResponsiveWrapper'

import { scaleBand, scaleLinear } from "d3-scale"
//import { max } from "d3-array"
import "d3-transition"

import './ChartDays.css'

class ChartDays extends Component {
  
  	constructor(props) {
   	super(props);
    	this.xScale = scaleBand()
    	this.yScale = scaleLinear()
  	}


  	render() {
  		const data = this.props.data
		  const margins = { top: 12, right: 12, bottom: 34, left: 12 }
		  const svgDimensions = {
   		 width: Math.max(this.props.parentWidth, 300),
   		 height: 200
 		}

		const maxValue = 10 //Math.max(...data.map(d => d.value))

		const xScale = this.xScale
			.padding(0.5)
			.domain(data.map(d => d.label))
			.range([margins.left, svgDimensions.width - margins.right])

		const yScale = this.yScale
			.domain([0, maxValue])
			.range([svgDimensions.height - margins.bottom, margins.top])

		return (
			<svg width={svgDimensions.width} height={svgDimensions.height}>
			   <Axes
          		scales={{ xScale, yScale }}
          		margins={margins}
          		svgDimensions={svgDimensions}
        		/>
        		<Bars
          		scales={{ xScale, yScale }}
          		margins={margins}
          		data={data}
          		maxValue={maxValue}
          		svgDimensions={svgDimensions}
        		/>
			</svg>
		)
  	}
}

export default ResponsiveWrapper(ChartDays)