import React, { Component } from 'react';

import ResponsiveWrapper from '../chart/ResponsiveWrapper'

import { scaleBand, scaleLinear } from "d3-scale"
import { ascending as d3Ascending } from "d3-array"
import { select as d3Select } from 'd3-selection'
import { axisLeft, axisBottom } from 'd3-axis'
import { interpolateLab } from 'd3-interpolate'

import "d3-transition"

import './ChartTasks.css'

class ChartTasks extends Component {
  
  	constructor(props) {
   	super(props);

		this.data = []

		this.props.data.sort(function (a, b) {
            return d3Ascending(a.value, b.value);
      })

      this.margin = {
			top: 15,
			right: 15,
			bottom: 15,
			left: 120
      }
      const width = Math.max(this.props.parentWidth, 300)

      this.width = width - this.margin.left - this.margin.right
      this.height = 500 - this.margin.top - this.margin.bottom

      this.colorScale = scaleLinear()
	      .domain([0,30,50,70,100])
   	   .range(['rgba(255,0,0,1)','rgba(255,0,0,0.5)','rgba(0,0,255,0.8)', 'rgba(124,179,66,0.8)','rgba(124,179,66,1)'])
      	.interpolate(interpolateLab)
  	}


	componentDidMount(){
//   	this.renderAxis()
  	}

  	componentDidUpdate(){
   	this.renderAxis()
  	}
  	
  	componentWillReceiveProps(nextProps){
		/*this.data = nextProps.data.sort(function (a, b) {
            return d3Ascending(a.value, b.value);
      })*/
      this.data =  nextProps.data.reverse()

      this.xScale = scaleLinear()
			.range([0, this.width])
			.domain([0,100])
			/*.domain([0, d3Max(this.data, function (d) {
				return d.value;
			})])*/

		this.yScale = scaleBand()
			.range([this.height, 0])
			.padding(0.5)
			.domain(this.data.map(function (d) {
				return d.name;
			}))
			.round(.1);
		
		this.renderAxis()
  	}

  	renderAxis() {
    	let axisY = axisLeft()
      	.scale(this.yScale)

      let axisX = axisBottom()
      	.scale(this.xScale)

       /*axis.tickSize(10)
        .tickPadding([4])
        .ticks([4])*/

   	d3Select(this.axisElementX).call(axisX)
   	d3Select(this.axisElementY).call(axisY)

  	}

   render(){        
		const bars = ( 
			this.data.map( d => {	return <g key={d.name}> <rect
					className="barra"
					y={ this.yScale(d.name) }
					x="0"
					fill={ this.colorScale(d.value) }
					height={ this.yScale.bandwidth()}
					width={ this.xScale(d.value) }
		      />
            </g>
		  	})        
		)

	  	/*const label = ( 
	   	this.data.map( d => {	return <text
	   			className="textlabel"
	   			key={d.name}
	   			y={ this.yScale(d.name) + this.yScale.bandwidth() / 2 + 4 - 10}
	   			x={ this.xScale(d.value) + 3}
	         >{d.name}</text>
	     	})        
	  	)*/
        
     // let barList = bars.map( b => { return <g>{b}</g> })
//     						{ label }

    	return <svg 
    				width={this.width + this.margin.left + this.margin.right} 
    				height={this.height + this.margin.top + this.margin.bottom} 
    				>
    					<g
    						ref={(el) => { this.axisElementX = el }} 
    						transform={"translate("+ this.margin.left+", "+ (this.height + this.margin.top) +")"}></g>

    					<g
    						ref={(el) => { this.axisElementY = el }} 
    						transform={"translate("+ this.margin.left+", "+ this.margin.top +")"}></g>
    					
    					<g transform={"translate(" + this.margin.left + "," + this.margin.top + ")"}>
     						{ bars }
     					</g>
     				</svg>

    }
}

export default ResponsiveWrapper(ChartTasks)