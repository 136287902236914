let apiUrl;
let domainUrl;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  apiUrl = 'http://localhost:5001/';
  domainUrl = 'http://localhost:3000/';
} else {
  apiUrl = 'https://x.hectormarti.com/kaizen/';
  domainUrl = 'https://kaizen.hectormarti.com/';
}


export const API_URL =	apiUrl;
export const DOMAIN_URL =	domainUrl;