import { dayInfoService } from '../services';
import { alertActions } from './';


export const dayInfoActions = {
    load,
    save,
}

export const DAY_INFO_LOAD_REQUEST = 'DAY_INFO_LOAD_REQUEST'
export const DAY_INFO_LOAD_SUCCESS = 'DAY_INFO_LOAD_SUCCESS'
export const DAY_INFO_LOAD_FAILURE = 'DAY_INFO_LOAD_FAILURE'

function load(date = '') {
    return dispatch => {
        dispatch(request( date ));   
        dayInfoService.load(date)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( date ) { 
        return { type: DAY_INFO_LOAD_REQUEST, date } 
    }
    function success(rows) { return { type: DAY_INFO_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: DAY_INFO_LOAD_FAILURE, error } }
}

export const DAY_INFO_SAVE_REQUEST = 'DAY_INFO_SAVE_REQUEST'
export const DAY_INFO_SAVE_SUCCESS = 'DAY_INFO_SAVE_SUCCESS'
export const DAY_INFO_SAVE_FAILURE = 'DAY_INFO_SAVE_FAILURE'

function save(date = '') {
    return dispatch => {
        dispatch(request( date ));   
        dayInfoService.save(date)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( date ) { 
        return { type: DAY_INFO_SAVE_REQUEST, date } 
    }
    function success(rows) { return { type: DAY_INFO_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: DAY_INFO_SAVE_FAILURE, error } }
}