import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { kaizenDayActions } from '../../actions'

import KaizenTask from './kaizen/KaizenTask';

var moment = require("moment")

class KaizenTaskListContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			task: {},
		};
		
		this.handleChange = this.handleChange.bind(this)
		this.handleBlur = this.handleBlur.bind(this)

		this.sliderChange = this.sliderChange.bind(this)
		this.sliderChangeComplete = this.sliderChangeComplete.bind(this)
	}

	componentWillMount() {
		this.setState({
			task: this.props.task
		})
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			task: nextProps.task
		})
	}

	sliderChangeStart = () => {
		console.log('Change event started')
	};

	sliderChangeComplete = () => {
		const {dispatch} = this.props
		let {task} = this.state
		
		if( task.task_type === "time" ){
			if( task.score > 0 ){
				task.time = moment().format("hh:mm:ss")
			}else{
				task.time = ""
			}
		}

		dispatch(kaizenDayActions.save( task ))
	};

	sliderChange(value){
		let task = this.state.task
	 	task.score = value

		this.setState({
			task: task,
		})
		//console.log("Trigger to update task",task);
	};

	handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        let newState = update( this.state, {
            task: { [name]: {$set: value} },
        });

        this.setState(newState)
    };

    handleBlur = (event) => {
        const {dispatch} = this.props
        dispatch(kaizenDayActions.save( this.state.task ))
    };

    render(){
    	const task = this.state.task   	
    	return <KaizenTask task={task} 
    		sliderChange={this.sliderChange}
    		sliderChangeStart={() => this.sliderChangeStart() }
    		sliderChangeComplete={() => this.sliderChangeComplete() }
    		handleChange={this.handleChange}
    		handleBlur={this.handleBlur} />
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KaizenTaskListContainer);
