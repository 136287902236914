import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import { taskActions } from '../../actions'

import TaskAdd from './task/TaskAdd'

var moment = require("moment");


class TaskAddContainer extends Component {
	constructor (props) { 		
		super(props);

		this.state = {
			task: this.emptyTask(),			
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	emptyTask(){
		let emptyTask = { 
			name: "",
			type: "",
			score: "",
			order: 0,
			ini: moment().format("YYYY-MM-DD"),
			end: "",
		}
		return emptyTask;
	}

	setEmptyTask(){
		let newState = update( this.state, {
			task: {$set: this.emptyTask()}
		});		
		this.setState( newState );
	}

	handleChange(event) {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			task: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit(event) {
        const {dispatch} = this.props

        dispatch( taskActions.add(this.state.task))
        this.setEmptyTask()
	}

	render(){	
		return (
			<TaskAdd 
				task={this.state.task}
				handleChange={this.handleChange}
				handleSubmit={this.handleSubmit} />
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskAddContainer);