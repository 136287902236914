import React, {Component} from 'react';
import PageGeneral from './PageGeneral';
import CalendarMenuContainer from './calendarMenuContainer/CalendarMenuContainer';
import KaizenDayContainer from './kaizenDayContainer/KaizenDayContainer';


class KaizenPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			year: "",
			month: "",
			day: "",
		}
    }

	componentDidMount(){
		this.setState({
			year: this.props.match.params.year,
			month: this.props.match.params.month,
			day: this.props.match.params.day,
		})
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			year: nextProps.match.params.year,
			month: nextProps.match.params.month,
			day: nextProps.match.params.day,
		})
	}

	render(){
		const menu = <CalendarMenuContainer
			year={this.state.year} 
			month={this.state.month}
			day={this.state.day}  />
		const main = <KaizenDayContainer 
			year={this.state.year} 
			month={this.state.month}
			day={this.state.day} />
		    
		return <PageGeneral menu={menu} main={main} /> 
	}
}

export default KaizenPage;