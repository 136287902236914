import React from 'react';

import './PageGeneral.css'
const PageGeneral = (props) => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-1 left-menu bg-light border-right">
                {props.menu}
            </div>
            <div className="col-11 main">
                {props.main}
            </div>
        </div>	
    </div>
);

export default PageGeneral;