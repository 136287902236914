import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';
import {getScore} from '../../functions'

import { summaryDayActions } from '../../actions'

import ChartDays from './ChartDays';

var moment = require("moment");


class StatsDayContainer extends Component { 
	constructor(props) {
        super(props);

        this.state = {
            scores: [],
        };

        this.statsDays = 30
    }
	
    componentWillReceiveProps(nextProps){
		const {dispatch} = this.props

    	if( !nextProps.date ) return false
		if( !nextProps.summaryDay.list ) return false

		const date = nextProps.date
		const summaryDay = nextProps.summaryDay.list
		
		const ini = moment(date).subtract(this.statsDays, 'days').clone()
		const end = moment(date).clone()
		const m = ini.clone()
		let scores = []
		while( m.isBefore(end) ){
			let year = m.format("YYYY")			
			let month = m.format("M")
			let day = m.format("D")
			
			if( !summaryDay[year]
				||!summaryDay[year][month]
				||!summaryDay[year][month][day] ){
				dispatch( summaryDayActions.loadByMonth(year, month ) )
				return false
			}else{
				let s = {
					day: m.format("YYYY-MM-DD"),
					label: m.format("DD MMM"),
					score: getScore( summaryDay[year][month][day].score, summaryDay[year][month][day].max_score),
				}
				scores.push(s)
			}
			m.add(1,'days')
		}

		let newState = update( this.state, {
			scores: {$set: scores},					
		});
		
		this.setState(newState)
		return true
    }

	render(){	
		if( !this.state.scores) return false

		return <ChartDays 
			data={this.state.scores} />
		
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		summaryDay: state.summaryDay, 
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsDayContainer);
