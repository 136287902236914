import React, {Component} from 'react';
import { connect } from 'react-redux'
import update from 'immutability-helper';

import { taskActions } from '../../actions'

import Task from './task/Task'

class TaskContainer extends Component { 

    constructor(props) {
        super(props);

        this.state = {
            task: {}
        };
        this.handleChange = this.handleChange.bind(this)
        this.handleBlur = this.handleBlur.bind(this)

    }

    componentWillMount( ){
        this.setState({
            task: this.props.task
        })
    }

    handleChange = (event) => {             
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        
        let newState = update( this.state, {
            task: { [name]: {$set: value} },
        });
        if( name === "ini"){
            newState = update( newState, {
                task: { ini_date: {$set: value} },
            });
        }else if( name === "end"){
            newState = update( newState, {
                task: { end_date: {$set: value} },
            });
        }

        this.setState(newState)
    };

    handleBlur = (event) => {
        const {dispatch} = this.props
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        dispatch(taskActions.saveField( this.state.task.id, name, value )) 
    };

    render(){
        if( !this.state.task ) return false
        return <Task 
            handleChange={this.handleChange}
            handleBlur={this.handleBlur}
            task={this.state.task} />
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);
