import { summaryMonthService } from '../services';
import { alertActions } from './';


export const summaryMonthActions = {
    load,
}

export const SUMMARY_MONTH_LOAD_REQUEST = 'SUMMARY_MONTH_LOAD_REQUEST'
export const SUMMARY_MONTH_LOAD_SUCCESS = 'SUMMARY_MONTH_LOAD_SUCCESS'
export const SUMMARY_MONTH_LOAD_FAILURE = 'SUMMARY_MONTH_LOAD_FAILURE'

function load(dateFrom = '', dateTo = '') {
    return dispatch => {
        dispatch(request( dateFrom, dateTo));   
        summaryMonthService.load(dateFrom, dateTo)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(dateFrom, dateTo) { 
        let datePeriod = {dateFrom: dateFrom, dateTo: dateTo}
        return { type: SUMMARY_MONTH_LOAD_REQUEST, datePeriod } 
    }
    function success(rows) { return { type: SUMMARY_MONTH_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: SUMMARY_MONTH_LOAD_FAILURE, error } }
}