import { kaizenDayService } from '../services';
import { alertActions } from './';


export const kaizenDayActions = {
    load,
    save,
}

export const KAIZEN_DAY_LOAD_REQUEST = 'KAIZEN_DAY_LOAD_REQUEST'
export const KAIZEN_DAY_LOAD_SUCCESS = 'KAIZEN_DAY_LOAD_SUCCESS'
export const KAIZEN_DAY_LOAD_FAILURE = 'KAIZEN_DAY_LOAD_FAILURE'

function load(date = '') {
    return dispatch => {
        dispatch(request( date ));   
        kaizenDayService.load(date)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( date ) { 
        return { type: KAIZEN_DAY_LOAD_REQUEST, date } 
    }
    function success(rows) { return { type: KAIZEN_DAY_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: KAIZEN_DAY_LOAD_FAILURE, error } }
}

export const KAIZEN_DAY_SAVE_REQUEST = 'KAIZEN_DAY_SAVE_REQUEST'
export const KAIZEN_DAY_SAVE_SUCCESS = 'KAIZEN_DAY_SAVE_SUCCESS'
export const KAIZEN_DAY_SAVE_FAILURE = 'KAIZEN_DAY_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        kaizenDayService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: KAIZEN_DAY_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: KAIZEN_DAY_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: KAIZEN_DAY_SAVE_FAILURE, error } }
}