import {fetchApi} from '../helpers';

export const dayInfoService = {
    load,
    save,
};

function load(date) {
    const res  = fetchApi('api/dayInfoLoad', {
        date: date,
    }) 

    return res
}

function save(dayInfo) {
    const res  = fetchApi('api/dayInfoSave', {
        dayInfo: dayInfo,
    }) 

    return res
}