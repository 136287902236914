import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom';

import Auth from '../modules/Auth';

import { PrivateRoute } from './PrivateRoute';

import KaizenPage from '../components/KaizenPage';
import ManagementPage from '../components/ManagementPage';
import LoginContainer from '../components/loginContainer/LoginContainer';
import Chart from '../components/Chart';


import { alertActions } from '../actions';
import { history } from '../helpers/history';

import { library } from '@fortawesome/fontawesome-svg-core'

import { faSignOutAlt, faTasks, faCaretDown, faCaretUp, faArrowsAltV, faCheckCircle, faTrashAlt, faPlay, faPause, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

library.add( faSignOutAlt, faTasks, faCaretDown, faCaretUp, faArrowsAltV, faCheckCircle, faTrashAlt, faPlay, faPause, faTimesCircle)

class App extends Component { 

  	constructor(props) {
   	super(props);

    	const { dispatch } = this.props;
    	history.listen((location, action) => {
      	// clear alert on location change
       	dispatch(alertActions.clear());
    	});
   }

   render(){
      return <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/logout" render={ () => {
                Auth.deauthenticateUser()
                return <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />             
              }
            }
          />

			    <PrivateRoute exact path="/" component={KaizenPage} />
			    <PrivateRoute exact path="/day/:year-:month-:day" component={KaizenPage} />
          <PrivateRoute exact path="/management/" component={ManagementPage} />
          <PrivateRoute exact path="/chart/" component={Chart} />

			</Switch>
    }
}

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  return {
    auth: authentication,
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));  