import React from 'react';
import KaizenTaskContainer from '../KaizenTaskContainer';
import AlertContainer from '../../AlertContainer';
import './KaizenDay.css';

var moment = require("moment");


const KaizenDay = (props) => {	
	const taskList = props.taskList

	return(			
		<div className="card">
			<div className="card-body">
			    <AlertContainer />

				<h5 className="card-title">
					{moment(props.dayInfo.date).format("dddd, DD of MMMM ")}
					<input type="text" 
						className="input-city"
						placeholder="City" 
						onChange={props.handleChangeDayInfo} 
						onBlur={props.handleBlurDayInfo} 
						name="city"
						value={props.dayInfo.city} />
				</h5>
				<div className="card-text" style={{marginTop: "16px"}}>
					{Object.keys(taskList).sort(function(a, b){
            				return taskList[a].task_order - taskList[b].task_order
        				}).map( (t) => {
							const task = taskList[t]						
							return <KaizenTaskContainer key={task.task_id} task={task} />
					})}
				</div>
			</div>
		</div>
	)
}

export default KaizenDay;  