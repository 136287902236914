import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import './Countdown.css'
import update from 'immutability-helper';

var moment = require("moment");
var momentDurationFormatSetup = require("moment-duration-format");

class Countdown extends Component {    
    constructor(props) {
      super(props)

      this.state = {
        hours:  0,
        minutes: 0,
        seconds: 0,
        secondsRemaining: 0,
        secondsElapsed: 0,
        status: '',
      }
      
      this.tick = this.tick.bind(this);              
      this.startCounter = this.startCounter.bind(this);      
      this.pauseCounter = this.pauseCounter.bind(this);             
      this.stopCounter = this.stopCounter.bind(this);             
      this.finishCounter = this.finishCounter.bind(this);             

      this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {  

      if( !this.props.duration ) return false
     
      if( false ) console.log( momentDurationFormatSetup ) // To avoid warning

      let duration = moment.duration( this.props.duration, "seconds" ).format("hh:mm:ss", { trim: false }).split(":")
      let hours = parseInt(duration[0], 10)
      let minutes = parseInt(duration[1], 10)
      let seconds = parseInt(duration[2], 10)
      let secondsRemaining = this.getSecondsRemaining(hours,minutes,seconds)

      this.setState({
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        secondsRemaining: secondsRemaining,
      })
    }

    componentWillUnmount() {
      clearInterval(this.interval)
    }

    handleChange(event) {
      const target = event.target
      const value = target.type === 'checkbox' ? target.checked : target.value
      const name = target.name
      
      let hours = (name === "hours")?value: this.state.hours
      let minutes = (name === "minutes")?value: this.state.minutes
      let seconds = (name === "seconds")?value: this.state.seconds
      let secondsRemaining = this.getSecondsRemaining(hours,minutes,seconds)

      let newState = update( this.state, {
        secondsRemaining: {$set: secondsRemaining},
        [name]: {$set: value},
      });
      this.setState( newState )
      
    }

    getSecondsRemaining(hours, minutes, seconds){
      let secondsRemaining = 3600 * hours + 60 * minutes + seconds;

      return secondsRemaining
    }

    tick() {      
      let secondsRemaining = this.state.secondsRemaining
      let secondsElapsed = this.state.secondsElapsed

      secondsRemaining = secondsRemaining - 1
      secondsElapsed = secondsElapsed + 1

      this.setState({
        secondsRemaining: secondsRemaining,
        secondsElapsed: secondsElapsed
      });

      if( secondsElapsed % 20 === 0){
        this.props.onUpdate( this.state.secondsElapsed )
      }
     
      if(secondsRemaining <= 0) {
        this.finishCounter()
      }
    }

    startCounter(){
      this.interval = setInterval(this.tick, 1000);
      
      let newState = update( this.state, {
        status: {$set: "running"},
      });
      this.setState( newState )

      this.props.onUpdate( this.state.secondsElapsed)
    }

    pauseCounter(){
      let status = (this.state.status === "paused")?"running":"paused"
  
      if(status === "paused" ){
        clearInterval(this.interval)
      }else{
        this.interval = setInterval(this.tick, 1000);
      }

      let newState = update( this.state, {
        status: {$set: status},
      });
      this.setState( newState )

      this.props.onUpdate( this.state.secondsElapsed)
    }

    stopCounter(){
      clearInterval(this.interval)
      let newState = update( this.state, {
        status: {$set: ""},
      });
      this.setState( newState )

      this.props.onUpdate( this.state.secondsElapsed)      
    }

    finishCounter(){
      clearInterval(this.interval)
      let newState = update( this.state, {
        status: {$set: "finished"},
      });
      this.setState( newState )
    }

    render() {
      const remaining = moment.duration(this.state.secondsRemaining,"seconds").format("hh:mm:ss",{trim: false})
      const hours = remaining.split(":")[0]
      const minutes = remaining.split(":")[1]
      const seconds = remaining.split(":")[2]

  		return(
  			<div className={"row countdown status-"+ this.state.status}>
  				<div style={{float: "left",marginRight: "10px"}}>          
            <button className="btn btn-primary btn-sm button-run" 
              onClick={() => this.startCounter()}
              >
              <FontAwesomeIcon icon="play" />
            </button>
            <button className={"btn btn-primary btn-sm button-pause " + (this.state.status==="paused"?"active":"") }
              onClick={() => this.pauseCounter()}
              >
              <FontAwesomeIcon icon="pause" />
            </button>
          </div>
          <div className="counter-edit">
              <input type="text" name="hours"
                onChange={this.handleChange}
                value={this.state.hours}/>

              <span className="separator"> : </span>
              
              <input type="text" name="minutes" 
                onChange={this.handleChange}
                value={this.state.minutes} />
              
              <span className="separator"> : </span>
              
              <input type="text" name="seconds" 
                onChange={this.handleChange}
                value={this.state.seconds}/> 
          </div>
          <div className="counter-run" >
              <span>
                <input type="text" value={hours} readOnly/>
              </span>
              <span className="separator"> : </span>
              <span>
                <input type="text" value={minutes} readOnly />
              </span>
              <span className="separator"> : </span>
              <span>
                <input type="text" value={seconds} readOnly />
              </span>
          </div>
          <div> 
            <button className="btn button-stop"  onClick={() => this.stopCounter()}>
              <FontAwesomeIcon icon="times-circle" />
            </button>
          </div>
  			</div>
  		)
	}
}

export default Countdown;  