import React, {Component} from 'react';

import {taskType} from '../../../constants/';

import './TaskAdd.css';

class TaskAdd extends Component {    	

	render() {
		const task = this.props.task
		return(
			<div className="row task-add">
				<div className="col-2 form-group">
					<input type="text" className="form-control" placeholder="Name"
				    	name="name" value={task.name} 
				    	onChange={this.props.handleChange} />
				</div> 
				<div className="col form-group">
					<select className="form-control" name="type" 
						value={task.type} onChange={this.props.handleChange} >
						<option value=""> Normal </option>
						{
							Object.keys(taskType).map( (t) => {
								let type = taskType[t]
								return <option key={type.value} value={type.value}>{type.label}</option>
							})
						}
					</select>					    	
		  		</div> 
		  		<div className="col form-group">
		  			<input type="number" name="score" value={task.score}
		  				placeholder="Score"
		  				className="form-control"
		  				onChange={this.props.handleChange}
		  			/>
		  		</div>

		  		<div className="col form-group">
		  			<input type="date" name="ini" value={task.ini}
		  				className="form-control"
		  				onChange={this.props.handleChange}
		  			/>
		  		</div>
		  		<div className="col form-group">
		  			<input type="date" name="end" value={task.end}
		  				className="form-control"
		  				onChange={this.props.handleChange}
		  			/>
		  		</div>
				
				<div className="col">
					<button type="button" className="btn btn-primary"
  						onClick={this.props.handleSubmit}
  						>Add</button>
				</div>
				
		  	</div>
		)
	}
}

export default TaskAdd;  