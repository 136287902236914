import React, {Component} from 'react';
import PageGeneral from './PageGeneral';
import CalendarMenuContainer from './calendarMenuContainer/CalendarMenuContainer';
import ManagementContainer from './managementContainer/ManagementContainer';

var moment = require("moment");

class ManagementPage extends Component {

	render(){
		const menu = <CalendarMenuContainer
			year={moment().format("YYYY")} 
			month={moment().format("MM")}
			day={moment().format("DD")}  />
		const main = <ManagementContainer />
		    
		return <PageGeneral menu={menu} main={main} /> 
	}
}

export default ManagementPage;