import React from 'react';
import {  NavLink } from 'react-router-dom';
import {getScorePercent} from '../../../functions'
import KaizenScore from './../../KaizenScore'

import './DayMenu.css'

const DayMenu = (props) => { 
    const day = props.day;
    const scorePercent = (day.summary)?getScorePercent(day.summary.score, day.summary.max_score):0
    return (
        <div>
            <NavLink className="day-menu"
                activeClassName='bill-active' style={{padding: 0}}
                to={`/day/${day.date.format("YYYY-MM-DD")}`} >      
                <div className="day-menu-number">
                    {day.date.format("DD")} 
                </div>
                <div className="day-menu-city">
                    {(day.summary && day.summary.city)?<small>{day.summary.city}</small>:""}                   
                </div>
            </NavLink>
            <div className="menu-day-score">
                <KaizenScore percent={scorePercent} />
            </div>
        </div>

    );
}

export default DayMenu;