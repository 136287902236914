import {
  KAIZEN_DAY_LOAD_REQUEST,
  KAIZEN_DAY_LOAD_SUCCESS,
  KAIZEN_DAY_LOAD_FAILURE,
  KAIZEN_DAY_SAVE_REQUEST,
  KAIZEN_DAY_SAVE_SUCCESS,
  KAIZEN_DAY_SAVE_FAILURE,
} from '../actions/kaizenDay'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const kaizenDay = (state = [], action) => {
	let newState = ""
	let rows = ""
	switch (action.type) {
		case KAIZEN_DAY_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case KAIZEN_DAY_LOAD_SUCCESS:
			const taskList = {}
			action.rows.map((task) => {
				taskList[task.task_id] =	task
				return true;
			})

			const year = action.rows[0].year;
			const month = action.rows[0].month;
			const day = action.rows[0].day;
			
			newState = Object.assign( {}, state )
			if( action.rows ){				 
				newState.list = newState.list || {};
          		newState.list[year] = newState.list[year] || {};
          		newState.list[year][month] = newState.list[year][month] || {};
    		    newState.list[year][month][day] = newState.list[year][month][day] || {};

				newState = update( newState, {
					isFetching: {$set: false},
					list: {
					  	[year] :{
						 	[month]: {
								[day]: {$set: taskList}
							}
					  	}
					},
				});
			}
			return newState 

		case KAIZEN_DAY_LOAD_FAILURE:
			return {};

		case KAIZEN_DAY_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case KAIZEN_DAY_SAVE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {
					  	[row.year] :{
						 	[row.month]: {
								[row.day]: {
									[row.task_id]:	{$set: row}
								}
							}
					  	}
					},
				});
			}
			return newState 

		case KAIZEN_DAY_SAVE_FAILURE:
			return {};

		default:
			return state
  }
}

export default kaizenDay