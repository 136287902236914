import React, {Component} from 'react';
import { connect } from 'react-redux'

import { summaryMonthActions } from '../../actions'

import MonthMenuContainer from './MonthMenuContainer'

var moment = require("moment");

class CalendarMenuContainer extends Component { 
	componentWillMount(){
		const dispatch = this.props.dispatch
		dispatch( summaryMonthActions.load(moment().subtract(3,'years').format("YYYY-MM-DD"),'') )
	}

	render(){
		var today = moment()
		var startDate = moment().subtract(3,'years').clone()
		const summaryMonth = this.props.summaryMonth
		let monthList = []

		const summaryMonthList = ( summaryMonth.list )?summaryMonth.list:""

		while (startDate.isBefore(today.endOf('month'))) {
			const year = parseInt(startDate.format("YYYY"),10)
			const month = parseInt(startDate.format("MM"),10)
			let sumMonth = {
				year: year,
				month: month,
				date: startDate.format("YYYY-MM-DD"),
			}
			if( summaryMonthList[year] && summaryMonthList[year][month] ){
				 sumMonth['summary'] = summaryMonthList[year][month]
			}
			
			monthList.push(sumMonth);
			startDate = startDate.add(1, "month");
		}
	
		monthList.reverse()

		return  (
			monthList.map( (summaryMonth) => {             
            	return(
                	<MonthMenuContainer 
                		key={summaryMonth.year +"-"+ summaryMonth.month} 
                		summaryMonth={summaryMonth} 
                		year={this.props.year}
                		month={this.props.month}
                		day={this.props.day}
                		/>
            	)
        	})
		)
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
	 summaryMonth: state.summaryMonth, 
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMenuContainer);
