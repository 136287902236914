import {fetchApi} from '../helpers';

export const summaryDayService = {
    loadByMonth,
};

function loadByMonth(year = '', month = '') {

    const res  = fetchApi('api/summaryDayLoadByMonth', {
    	year: year,
        month: month,
    }) 

    return res
}