import {
  DAY_INFO_LOAD_REQUEST,
  DAY_INFO_LOAD_SUCCESS,
  DAY_INFO_LOAD_FAILURE,
  DAY_INFO_SAVE_REQUEST,
  DAY_INFO_SAVE_SUCCESS,
  DAY_INFO_SAVE_FAILURE,
} from '../actions/dayInfo'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const dayInfo = (state = [], action) => {
	let newState = "";
	switch (action.type) {
		case DAY_INFO_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case DAY_INFO_LOAD_SUCCESS:
			let row = Object.assign({},action.rows)
			newState = Object.assign( {}, state )
			if( row ){				
				newState.list = newState.list || {};
				newState.list[row.year] = newState.list[row.year] || {};
				newState.list[row.year][row.month] = newState.list[row.year][row.month] || {};
				newState.list[row.year][row.month][row.day] = newState.list[row.year][row.month][row.day] || {};

				newState = update( newState, {
					isFetching: {$set: false},
					list: {
					  	[row.year] :{
						 	[row.month]: {
								[row.day]: {$set: row}
							}
					  	}
					},
				});
			}
			return newState 

		case DAY_INFO_LOAD_FAILURE:
			return {};

		case DAY_INFO_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case DAY_INFO_SAVE_SUCCESS:
			let rows = Object.assign({},action.rows)
			if( rows.dayInfo ){
				let row = rows.dayInfo
				newState = update( state, {
					isSaving: {$set: false},
					list: {
					  	[row.year] :{
						 	[row.month]: {
								[row.day]: {$set: row}
							}
					  	}
					},
				});
			}
			return newState 

		case DAY_INFO_SAVE_FAILURE:
			return {};
		default:
			return state
  }
}

export default dayInfo