import React, {Component} from 'react';

import TaskDeleteContainer from '../TaskDeleteContainer';

import {taskType} from '../../../constants/';

import './Task.css';

class KaizenTask extends Component {    	

	render() {
		const task = this.props.task
		return(
			<div className="row" style={{marginLeft: '10px'}}>
				<div className="col-2 ">
					<input type="text" name="name" value={task.name} 
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur}
						className="form-control" />					
				</div>
				<div className="col">
					<select className="form-control" name="type" 
						value={task.type} 
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur} >
						<option value=""> Normal </option>
						{
							Object.keys(taskType).map( (t) => {
								let type = taskType[t]
								return <option key={type.value} value={type.value}>{type.label}</option>
							})
						}
					</select>
		
					<input type="checkbox" value={task.compensable}
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur}
						className="form-control" />					
				</div>
				<div className="col">
					<input type="text" name="score" value={task.score}
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur}
						className="form-control" />					
				</div>				
				<div className="col">
					<input type="date" name="ini" value={task.ini_date} 
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur}
						className="form-control" />					
				</div>
				<div className="col">
					<input type="date" name="end" value={task.end_date}
						onChange={this.props.handleChange}
						onBlur={this.props.handleBlur} 
						className="form-control" />					
				</div>
				<div className="col">
					<TaskDeleteContainer id={task.id} />
				</div>
			</div>
		)
	}
}

export default KaizenTask;  