import {
  SUMMARY_MONTH_LOAD_REQUEST,
  SUMMARY_MONTH_LOAD_SUCCESS,
  SUMMARY_MONTH_LOAD_FAILURE,
} from '../actions/summaryMonth'

import {
  KAIZEN_DAY_SAVE_REQUEST,
  KAIZEN_DAY_SAVE_SUCCESS,
  KAIZEN_DAY_SAVE_FAILURE,
} from '../actions/kaizenDay'

import update from 'immutability-helper';
import {mergeDeep} from '../functions'
//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const summaryMonth = (state = [], action) => {
  let newState = ""
  let rows = ""
  switch (action.type) {
    case SUMMARY_MONTH_LOAD_REQUEST:
      return {
        isFetching: true,
      };
    case SUMMARY_MONTH_LOAD_SUCCESS:
      rows = {...action.rows}
      newState = {...state}
      if( rows ){
        Object.keys(rows).map((r) => {
          let row = rows[r]     
          newState = mergeDeep({list:{[row.year]: {[row.month]: {} } }},newState)

          newState = update( newState, {
            isFetching: {$set: false},
            list: {
              [row.year] :{
                [row.month]: {$set: row}
              }
            },
          });
          return true
        })
      }
      return newState 

    case SUMMARY_MONTH_LOAD_FAILURE:
      return {}
    
    case KAIZEN_DAY_SAVE_REQUEST:
      newState = update( state, {
        isSaving: {$set: true},         
      });
      return newState 
    case KAIZEN_DAY_SAVE_SUCCESS:
      rows = {...action.rows}
      newState = {...state}
      if( rows.summaryMonth ){
         let row = rows.summaryMonth
         newState = mergeDeep({'list':{ [row.year]:{[row.month] : {} } }},newState)

         newState = update( newState, {
            isSaving: {$set: false},
            list: {
               [row.year] :{
                  [row.month]: {$set: row}
               }
            },
         });
      }
      return newState 

    case KAIZEN_DAY_SAVE_FAILURE:
      return {}
    default:
      return state
  }
}

export default summaryMonth