import React from 'react';

import UserInfo from '../components/UserInfo';
import StatsDaysContainer from '../components/statsDaysContainer/StatsDaysContainer';
import StatsTasksContainer from '../components/statsTasksContainer/StatsTasksContainer';

import './ColStats.css'
const ColStats = (props) => (
	<div>
		<UserInfo userName="Hector" />
	    
	    <div className="stats-label">Global Score</div>    
	    
	    <div className="card">
	        <StatsDaysContainer date={props.date} />                    
	    </div>
	    
	    <div className="stats-label">Tasks Score</div>
	    <div className="card">
	    	<StatsTasksContainer date={props.date} />
	    </div>
    </div>
);

export default ColStats;