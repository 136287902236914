import React, {Component} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import TaskContainer from './../../taskContainer/TaskContainer'
import TaskAddContainer from './../../taskContainer/TaskAddContainer'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import './Management.css';

var moment = require("moment");

class KaizenTask extends Component {    	
	render() {
		if( !this.props.taskList) return false
		
		const DragHandle = SortableHandle(() => <span className="task-handler" ><FontAwesomeIcon icon="arrows-alt-v" /></span>);
		
		const SortableItem = SortableElement( ({value}) => {
			let classLi = []
			if(
				!moment().isBetween(moment(value.ini),moment(value.end),null,'[)')
				&& moment(value.end).isValid() ){
				classLi.push("expired")
			}
			return <li className={classLi.join( )} >
				<DragHandle  />
  				<TaskContainer style={{float: "left"}}
					task={value} key={value.id} /></li>
		});

		const SortableList = SortableContainer(({items}) => {
  			return (
    		<ul className="sortable-task">
      			{
              Object.keys(items).map((i) => {
                return <SortableItem key={`item-${i}`} index={parseInt(i,10)} value={items[i]} />
              })
            }
    		</ul>
  			);
		});

		const showExpired = (this.props.showExpired)?"showExpired":""
		const btnExpired = (this.props.showExpired)?"btn-primary":"btn-outline-primary"

		return <div className="row management-container"> 
    		<div className={"col-9 management-col " + showExpired}>
         		<TaskAddContainer />
           		<SortableList items={this.props.taskList} 
           			onSortEnd={this.props.onSortEnd}
           			useDragHandle={true} /> 
       		</div>

       		<div className="col-3 management-menu-col bg-light border-left"> 
         		<button type="button" className={"btn "+ btnExpired }
         			onClick={this.props.handleShowExpiredClick}> Show Expired Tasks</button>
       		</div>
		</div>
	}
}

export default KaizenTask;  