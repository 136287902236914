import React from 'react';
import {getDayScore} from '../functions';
import './DayScore.css'
import KaizenScore from './KaizenScore'
import {getScorePercent} from '../functions'


const DayScore = (props) => {
    if( !props.taskList )return false
    
    const taskList = props.taskList
    const score =   getDayScore( taskList )
	const scorePercent = getScorePercent( score, 10 )
	
    return (
    <div className="day-score">
        <div className="day-score-shadow"> </div>
    	<KaizenScore percent={scorePercent} vertical /> 
    </div>
)}

export default DayScore;