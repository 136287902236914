import React, {Component} from 'react';
import { connect } from 'react-redux'
import { arrayMove} from 'react-sortable-hoc';

import { taskActions } from '../../actions'
import Management from './management/Management'

import { convertToArray } from '../../functions'

class ManagementContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			taskList: [],
			showExpired: false
		};
	}
	
	componentWillMount( ){
		const {dispatch} = this.props
		dispatch( taskActions.load() )
	}

	componentWillReceiveProps( nextProps ){
		if( nextProps.task.list){
			this.setTaskList( nextProps.task.list )
		}
	}

	handleShowExpiredClick = () => {
		this.setState({
			showExpired: !this.state.showExpired
		})
	}

	setTaskList(taskList){
		let list = convertToArray({...taskList})
		list.sort(function(a, b){
			return a.order - b.order
		});

		this.setState({
			taskList: list
		});
	}
	
  	onSortEnd = ({oldIndex, newIndex}) => {
		const {dispatch} = this.props

		this.setState({
			taskList: arrayMove(this.state.taskList, oldIndex, newIndex),
		});

		let orderTask =	[]
		let pos = 0
		this.state.taskList.map( t => {
			orderTask.push({
				task_id: t.id,
				name: t.name, 
				order: pos
			})
			pos++

			return true
		})
		dispatch( taskActions.saveOrder( orderTask ) )
	};

	render(){
		if( !this.props.task.list ) return false

		return <Management 
					showExpired={this.state.showExpired}
					handleShowExpiredClick={this.handleShowExpiredClick}
					taskList={this.state.taskList} 
					onSortEnd={this.onSortEnd} />
	}
}

const mapStateToProps = (state, ownProps) => {
  return {
	  task: state.task,     
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementContainer);
