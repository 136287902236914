import {
  TASK_LOAD_REQUEST,
  TASK_LOAD_SUCCESS,
  TASK_LOAD_FAILURE,
  TASK_ADD_REQUEST,
  TASK_ADD_SUCCESS,
  TASK_ADD_FAILURE,
  TASK_SAVE_FIELD_REQUEST,
  TASK_SAVE_FIELD_SUCCESS,
  TASK_SAVE_FIELD_FAILURE,
  TASK_DELETE_REQUEST,
  TASK_DELETE_SUCCESS,
  TASK_DELETE_FAILURE,
} from '../actions/task'


import {convertObjectId} from '../functions'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const dayInfo = (state = [], action) => {
	let newState = "";
	let rows = "";

	switch (action.type) {
		case TASK_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case TASK_LOAD_SUCCESS:
			let row = convertObjectId(action.rows)
			newState = Object.assign( {}, state )
			if( row ){				
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: row},
				});
			}
			return newState 

		case TASK_LOAD_FAILURE:
			return {};

		case TASK_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TASK_ADD_SUCCESS:
			let t = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
				  	[t.id]: {$set: t}
				},
			});
			return newState 

		case TASK_ADD_FAILURE:
			return {};

		case TASK_SAVE_FIELD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TASK_SAVE_FIELD_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
					[rows.id]: {$set: rows}
				},
			});

			return newState 

		case TASK_SAVE_FIELD_FAILURE:
			return {};

		case TASK_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TASK_DELETE_SUCCESS:
			let id = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$unset: [id]},
			});
			return newState 

		case TASK_DELETE_FAILURE:
			return {};
		default:
			return state
  }
}

export default dayInfo