import React from 'react';
import {Collapse} from 'react-collapse';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import DayMenu from './DayMenu'
import KaizenScore from './../../KaizenScore'

import {getScorePercent} from '../../../functions'
import './MonthMenu.css'

var moment = require("moment");

const MonthMenu = (props) => {
	const month = props.summaryMonth;
	const dayList = props.summaryDayList        
	const isOpen = props.isOpen;

	let classRow = [];
	classRow.push("parent")
	if(isOpen) classRow.push("open")

	const scorePercent = (month.summary)?getScorePercent( month.summary.score, month.summary.max_score ): 0
	let monthLabel = moment(month.date).format("MMMM")
	let yearLabel = ""
	if (moment(month.date).format("YYYY") !== moment().format("YYYY")){
		yearLabel = <small className="year-label">{moment(month.date).format("YY")}</small>
	}
	return (
	  	<ul className="sidebar">
			<li className={classRow.join(" ")} >
				<div className="month-toggle" 
					onClick={() => props.toggleMonth()} >
					<div className="month-toggle-label">
						{monthLabel}{yearLabel}              
						<span className="caret caret-down">
							 <FontAwesomeIcon icon="caret-down" />
						</span>
						<span className="caret caret-up">
							 <FontAwesomeIcon icon="caret-up" />
						</span>
					</div>
					<div className="menu-month-score">
						<KaizenScore percent={scorePercent} />
					</div>                        
				</div>                    
				<Collapse isOpened={isOpen} hasNestedCollapse={true} >
					<ul className="submenu" >
						{dayList.map( (day) => {
							return (
								<li key={day.date.format("YYYYMMDD")}>
									<DayMenu day={day} />
								</li>
							)
						})}
					</ul>
				</Collapse>                   
			</li>
		</ul>
	);
}

export default MonthMenu;