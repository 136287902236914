import {fetchApi} from '../helpers';

export const summaryMonthService = {
    load,
};

function load(dateFrom = '', dateTo = '') {

    const res  = fetchApi('api/summaryMonthLoad', {
        dateFrom: dateFrom,
        dateTo: dateTo
    }) 

    return res
}