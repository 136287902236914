import React, {Component} from 'react';
import Slider from 'react-rangeslider'

import 'react-rangeslider/lib/index.css'

import Countdown from '../../../components/Countdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import './KaizenTask.css';

class KaizenTask extends Component {    	

	updateTaskByTime( time ){
		let {task} = this.props
		const maxScore = task.maxScore
		const totalDuration = task.duration
		const score = parseInt( (maxScore * time) / totalDuration ,10)
		task.status.score = score
		this.setState({
			task: task,
		})
	}

	render() {
		const {task,handleChange,handleBlur} = this.props
		if( !task ) return false
		
		const score = task.score
		const maxScore = task.max_score 
		
		let classSlider = ""		
		if( score === 0 ){}
			else if( score === maxScore ){
			classSlider = "slider-complete"
		}else if( score > 0 && score < maxScore){
			classSlider = "slider-middle"
		}else if( score < 0){
			classSlider = "slider-negative"
		}

		let extraInfo = "";
		switch( task.task_type ){
			case 'counter':
				extraInfo = <Countdown duration={task.duration} onUpdate={(seconds) => this.updateTaskByTime(seconds)}/>
				break;
			case 'time':
				extraInfo = <span>{task.time}</span>
				break;
			default:
		}

		return(
			<div className="row row-task">
				<div className="col-2">
					<span className="label-task">{task.task_name}</span>
				</div>
				<div className={"col-sm-4 " + classSlider}>
					<div className="row row-slider" style={{width: "100%"}}>
						<div className="col-1">
							{(task.compensable)?<FontAwesomeIcon icon="check-circle" size="lg" />:""}
						</div>
						<div className="col-10">
							<Slider
							 	style={{width: "100%"}}
								min={(task.minScore)?task.minScore:0}
								max={task.max_score}
								value={task.score}  

								onChangeStart={this.props.sliderChangeStart}
								onChange={this.props.sliderChange}
								onChangeComplete={this.props.sliderChangeComplete}
							/>
						</div>
					</div>
				</div>
				<div className={"col-4 type-"+ task.task_type}>
					{extraInfo}
				</div>
				<div className="col-2">
					<input type="text" name="log" className="form-control"
						value={task.log}
						onChange={handleChange}
						onBlur={handleBlur}
						 />
				</div>
			</div>
		)
	}
}

export default KaizenTask;  