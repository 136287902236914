import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import { interpolateLab } from 'd3-interpolate'

export default class Bars extends Component {
  constructor(props) {
    super(props)

    this.colorScale = scaleLinear()
        .domain([0,3,5,7,10])
        .range(['rgba(255,0,0,1)','rgba(255,0,0,0.5)','rgba(0,0,255,0.8)', 'rgba(124,179,66,0.8)','rgba(124,179,66,1)'])
        .interpolate(interpolateLab)      
  }

  render() {
    const { scales, margins, data, svgDimensions } = this.props
    const { xScale, yScale } = scales
    const { height } = svgDimensions
    
    if( !data ){
      return false
    }

    const bars = (
      data.map( datum => {
      	if(!datum.score || isNaN( datum.score ) ){
      		return false
      	}
      	
        	return <rect
         	key={datum.label}
         	x={xScale(datum.label)}
         	y={yScale(datum.score)}
         	height={height - margins.bottom - scales.yScale(datum.score)}
         	width={xScale.bandwidth()}
         	fill={this.colorScale(datum.score)}
        	/>
      })
    )

    return (
      <g>{bars}</g>
    )
  }
}