import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { summaryTaskActions } from '../../actions'

import ChartTasks from './ChartTasks';

var moment = require("moment");


class StatsTasksContainer extends Component { 
	constructor(props) {
        super(props);

        this.state = {
            taskScores: [],
        };

        this.statsDays = 30
    }
	
	componentWillMount(){
		this.componentWillReceiveProps(this.props)
	}

    componentWillReceiveProps(nextProps){
		const {dispatch} = this.props

    	if( !nextProps.date ) return false
		
		const date = nextProps.date
		//const yearDate = moment(date).format("YYYY")
		//const monthDate = moment(date).format("M")

		if( !nextProps.summaryTask.list ){
			dispatch( summaryTaskActions.loadByDates(
				moment(date).subtract(30,'days').format("YYYY-M-D"), 
				moment(date).format("YYYY-M-D") ) 
			)
			return false
		}

		if( !nextProps.summaryTask || !nextProps.summaryTask.list ) return false

		const summaryTask = nextProps.summaryTask.list

		let sumTaskList = []

		Object.keys(summaryTask).map((i) => {
			if( summaryTask[i].task_name ){
				let sumTask = {}
				let max_score = summaryTask[i].task_days * summaryTask[i].task_score
				sumTask.value = (100 * summaryTask[i].task_score_total) / max_score
				sumTask.name = summaryTask[i].task_name
	    		sumTaskList.push(sumTask)
	    	}
	    	return true
		})

		let newState = update( this.state, {
			taskScores: {$set: sumTaskList},					
		});
		
		this.setState(newState)
		return true
    }

	render(){	
		if( !this.state.taskScores ) return false
		const taskScores = Object.keys(this.state.taskScores).map((i) => this.state.taskScores[i] )
		return <ChartTasks data={taskScores} />
		
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		summaryTask: state.summaryTask, 
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsTasksContainer);
