export function getScore( taskScore, maxScore ){
  return parseFloat((taskScore / maxScore) * 10).toFixed(1)
}

export function getScorePercent( taskScore, maxScore ){
  const score = getScore(taskScore, maxScore) 
  return (score)?score*100/ 10:0
}

export function getDayScore( taskList ) {

	if (!taskList) return 0;
	let taskScore = 0
	let maxScore = 0

	Object.keys(taskList).map((t) =>{
		let task = taskList[t]
		taskScore = taskScore + task.score
		maxScore = maxScore + task.max_score
		return true
	})

	const score = getScore(taskScore, maxScore)

  return score;
}