import { taskService } from '../services';
import { alertActions } from './';

export const taskActions = {
    load,
    add,
    saveField,
    saveOrder,
    _delete,
}

export const TASK_LOAD_REQUEST = 'TASK_LOAD_REQUEST'
export const TASK_LOAD_SUCCESS = 'TASK_LOAD_SUCCESS'
export const TASK_LOAD_FAILURE = 'TASK_LOAD_FAILURE'

function load() {    
    return dispatch => {
        dispatch(request( ));   
        taskService.load()
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { 
        return { type: TASK_LOAD_REQUEST } 
    }
    function success(rows) { return { type: TASK_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: TASK_LOAD_FAILURE, error } }
}

export const TASK_ADD_REQUEST = 'TASK_ADD_REQUEST'
export const TASK_ADD_SUCCESS = 'TASK_ADD_SUCCESS'
export const TASK_ADD_FAILURE = 'TASK_ADD_FAILURE'

function add(task = '') {    
    return dispatch => {
        dispatch(request( task ));   
        taskService.add(task)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TASK_ADD_REQUEST, task } 
    }
    function success(rows) { return { type: TASK_ADD_SUCCESS, rows } }
    function failure(error) { return { type: TASK_ADD_FAILURE, error } }
}

export const TASK_SAVE_FIELD_REQUEST = 'TASK_SAVE_FIELD_REQUEST'
export const TASK_SAVE_FIELD_SUCCESS = 'TASK_SAVE_FIELD_SUCCESS'
export const TASK_SAVE_FIELD_FAILURE = 'TASK_SAVE_FIELD_FAILURE'

function saveField( id, name, value) {    
    return dispatch => {
        dispatch(request( id,name,value ));   
        taskService.saveField(id,name,value)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TASK_SAVE_FIELD_REQUEST, id,name,value } 
    }
    function success(rows) { return { type: TASK_SAVE_FIELD_SUCCESS, rows } }
    function failure(error) { return { type: TASK_SAVE_FIELD_FAILURE, error } }
}

export const TASK_SAVE_ORDER_REQUEST = 'TASK_SAVE_ORDER_REQUEST'
export const TASK_SAVE_ORDER_SUCCESS = 'TASK_SAVE_ORDER_SUCCESS'
export const TASK_SAVE_ORDER_FAILURE = 'TASK_SAVE_ORDER_FAILURE'

function saveOrder( orderList ) {    
    return dispatch => {
        dispatch(request( orderList ));   
        taskService.saveOrder( orderList)
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( orderList ) { 
        return { type: TASK_SAVE_ORDER_REQUEST, orderList } 
    }
    function success(rows) { return { type: TASK_SAVE_ORDER_SUCCESS, rows } }
    function failure(error) { return { type: TASK_SAVE_ORDER_FAILURE, error } }
}

export const TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST'
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS'
export const TASK_DELETE_FAILURE = 'TASK_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        taskService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: TASK_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: TASK_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: TASK_DELETE_FAILURE, error } }
}